import { useRef } from 'react';
import { logger } from 'src/app/Logger';
import { attachWindowLoad } from 'src/utils/windowLoad';
import { loadScript } from 'src/utils/html';
import { useEffectOnMounted } from 'src/hooks';

interface LivePersonContainerProps {
    shouldLoadWebChat?: boolean;
    isNativeApp?: boolean;
}

// Injects custom web-component tag used by the Abyss custom window script for native apps
const injectAbyssParcelComponent = (env = 'stage') => {
    const envSuffix = env === 'production' ? 'prod' : env;
    const importAttribute = `health-companion@${envSuffix}`;

    const component = document.createElement('abyss-parcel');
    component.setAttribute('import', importAttribute);
    document.body.appendChild(component);
};

export const LivePersonChatContainer: React.FC<LivePersonContainerProps> = ({ shouldLoadWebChat, isNativeApp }) => {
    const livePersonChatInitiatingRef = useRef(false);
    const {
        __LIVE_PERSON_WEB_CHAT: livePersonScriptUrl,
        __LIVE_PERSON_WEB_CHAT_CUSTOM_WINDOW: livePersonMobileScriptUrl,
        DEPLOY_ENV: environment,
    } = process.env;

    const initLivePersonWebChat = () => {
        if (livePersonChatInitiatingRef.current || !shouldLoadWebChat) return;

        // to ensure the 'init' function is only called once to prevent multiple invocations
        livePersonChatInitiatingRef.current = true;

        // start to import the js
        loadScript(livePersonScriptUrl)
            .then(() => {
                logger.info(`Live Person Chat Script Loaded`);
                if (isNativeApp && livePersonMobileScriptUrl) {
                    loadScript(livePersonMobileScriptUrl)
                        .then(() => {
                            logger.info(`Live Person Chat Mobile Script Loaded`);
                            injectAbyssParcelComponent(environment);
                        })
                        .catch((error: Error) => {
                            logger.warn('Error in Loading LivePerson WebChat Mobile', { error });
                        });
                }
            })
            .catch((error: Error) => {
                logger.warn('Error in Loading LivePerson WebChat', { error });
            });
    };

    useEffectOnMounted(() => {
        attachWindowLoad(initLivePersonWebChat)
            .then(() => {
                logger.info('Attached initLivePersonWebChat to window.onload');
            })
            .catch((error: Error) => {
                logger.error('Failed to attach initLivePersonWebChat to window.onload', { error });
            });
    });

    return null;
};
